<template>
  <main role="main">
    <meta-title :title="metaTitle"></meta-title>
    <section class="section-first-page-hero">
      <div class="slider">
        <div class="slider-container">
          <picture>
            <source
              srcset="@/assets/i/loti_480.png"
              style="min-height: 320px"
              media="(max-width: 374px)"
            />
            <source
              srcset="@/assets/i/loti_480.png"
              style="min-height: 320px"
              media="(max-width: 413px)"
            />
            <source
              srcset="@/assets/i/loti_768.png"
              style="min-height: 346px"
              media="(max-width: 767px)"
            />
            <source
              srcset="@/assets/i/loti_768.png"
              style="min-height: 346px"
              media="(max-width: 1023px)"
            />
            <source
              srcset="@/assets/i/loti_big.png"
              style="min-height: 404px"
              media="(max-width: 1279px)"
            />
            <source
              srcset="@/assets/i/loti_big.png"
              style="min-height: 404px"
              media="(max-width: 1439px)"
            />
            <source
              srcset="@/assets/i/loti_big.png"
              style="min-height: 404px"
              media="(max-width: 1599px)"
            />
            <img src="@/assets/i/loti_big.png" width="100%" alt="" />
          </picture>
        </div>
      </div>
    </section>
    <section class="section section-default section-text">
      <div class="container m-container-only-s">
        <div v-if="$i18n.locale === 'en'">
          <div class="content loti">
            <h4>
              LOTİ
            </h4>
            <p class="cursive">
              In LOTİ we believe that travel is about more than just seeing new places - it's about creating unforgettable experiences that you'll cherish for a lifetime.
              We are proud on our individual approach to booking tours. We know that there is no two same travelers. That's why we work closely with our partners to create personalized tours which match with the unique needs and interests.
              But it's not just our personalized approach that sets us apart. We're also passionate about creating travel experiences that inspire and delight. Our higly-experted team are always on the lookout for hidden gems and off-the-beaten-path destinations that will make you feel truly enriched and inspired.
            </p>
            <p class="cursive">
              LOTİ is the perfect choice for those who want to plunge into the world of luxury and comfort. We take care of every little detail to make your trip as comfortable and carefree as possible.
            </p>
            <p class="cursive">
              Fall in love with travel with LOTİ!
            </p>
            <h4
              style="
        margin-top:50px;"
            >
              AZUR air
            </h4>
            <p class="cursive">
              AZUR air is one of the leading charter airlines in Russia. It
              performs flights to popular tourist destinations on behalf of the
              largest Russian tour operators.
            </p>
            <p class="cursive">
              The airline's fleet includes 22 modern Boeing aircraft of various
              modifications: Boeing 737, Boeing 757, Boeing 767 and Boeing 777.
            </p>
            <p class="cursive">
              AZUR air is a team of professionals who are in love with their
              business. We give the opportunity to explore the world to all the
              people of our great country, make their travel affordable, bright
              and comfortable.
            </p>
          </div>
        </div>
        <div v-else>
          <div class="content loti">
            <h4>
              LOTİ
            </h4>
            <p class="cursive">
              LOTİ - вдохновляем на путешествия, которые меняют жизнь.

              Мы верим, что каждый клиент заслуживает индивидуального подхода, поэтому наши эксперты готовы предложить уникальные туры, которые подойдут под запросы самых требовательных клиентов. Наша цель - сделать ваше путешествие настоящим приключением, наполненным яркими впечатлениями и эмоциями.
            </p>
            <p class="cursive">
              LOTİ - это идеальный выбор для тех, кто хочет окунуться в мир роскоши и комфорта. Мы заботимся о каждой мелочи, чтобы ваше путешествие было максимально комфортным и беззаботным.
            </p>
            <p class="cursive">
              LOTİ - это настоящая команда профессионалов, которая готова сделать все возможное, чтобы вы полюбили путешествия так же, как мы их любим. Доверьте нам свои мечты об отдыхе, и мы сделаем все возможное, чтобы они стали реальностью.              Влюбляйтесь в путешествия вместе с LOTİ!
            </p>
            <h4 style="margin-top:50px;">
              AZUR air
            </h4>
            <p class="cursive">
              Крупнейшая чартерная авиакомпания России. Выполняет перевозки по
              популярным туристическим направлениям в интересах крупнейших
              российских туроператоров.
            </p>
            <p class="cursive">
              В парке авиакомпании 22 современных самолета Boeing различных
              модификаций: Boeing 737, Boeing 757, Boeing 767 и Boeing 777.
            </p>
            <p class="cursive">
              AZUR air — это команда профессионалов, влюбленных в своё дело. Мы
              даём возможность познавать мир всем жителям нашей большой страны,
              делаем их путешествия доступными, яркими и комфортными.
            </p>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: "InfoLoti",
  title() {
    return this.$t("navigation.info_loti");
  }
};
</script>

<style scoped></style>
